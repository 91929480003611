@import 'assets/styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  REACRT DRAFT WYSIWYG */

.public-DraftStyleDefault-block {
  margin: 0;
}

.DraftEditor-root {
  height: auto;
}
